import React, { useEffect, useRef } from 'react';
import '../Css/testimonials.css'; // Ensure the path is correct
import doctorImage from '../Images/Picture36.png'; // Update the path as needed

const Testimonials = () => {
  const counterRef = useRef('');
  const videoRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (videoRef.current) {
          const iframe = videoRef.current;
          const iframeSrc = iframe.src;

          if (entry.isIntersecting) {
            iframe.src = iframeSrc + "&autoplay=1"; // Resume video
          } else {
            iframe.src = iframeSrc.replace("&autoplay=1", ""); // Pause video
          }
        }
      },
      { threshold: 0.5 } // Play when at least 50% is visible
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);
  // useEffect(() => {
  //   if (counterRef.current) {
  //     console.log(33333,counterRef.current )
  //     const target = +counterRef.current.getAttribute('data-count');
  //     let count = 0;
  //     const increment = target / 200;
  
  //     const updateCount = () => {
  //       if (count < target) {
  //         count += increment;
  //         counterRef.current.innerText = Math.ceil(count) + '% NPS';
  
  //         setTimeout(updateCount, 10);
  //       } else {
  //         counterRef.current.innerText = target + '% NPS';
  //       }
  //     };
  
  //     updateCount();
  //   }
  // }, []);
  

  return (
    <div className="testimonials-page-container">
      {/* Separate container for the heading and description */}
      <div className="header-container">
        <h3 className="testimonials-heading">Testimonials</h3>
        <p className="testimonials-description">
          Hear from experts, what they say about RxOne
        </p>
      </div>

      <div className="testimonials-container">
        {/* Left Box: Doctor Information with Image */}
        <div className="left-content">
        <div className="testimonial-video-container">
        
      <iframe
        ref={videoRef}
        className="testimonial-video"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/R-QaSWIZVYQ?rel=0&enablejsapi=1"
        title="Testimonial Video"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe>
    </div>
        </div>

        {/* Right Box: Counter with Description */}
        <div className="right-content"style={{height:"150%"}}>
          <div className="New-box">
            <div style={{padding:"1%"}}>
            <h2 className="counter" data-count="87" ref={counterRef}>
              87% NPS
              
            </h2>
            </div>
            <div style={{fontFamily:"initial"}}>
            <p className="New-box-description">
              Most of our users recommend the solution to fellow care providers.
            </p>
            </div>
          </div>
        </div>
        
      </div>
      <div className="header-section">
                <h1 className="header-title" style={{textAlign: "center"}}>Why use RxOne? </h1>
            </div>
      {/* <div style={{display:"flex",justifyItems:"center"}}><h1>why to use rxone?</h1></div> */}
      
    </div>
  );
};

export default Testimonials;
